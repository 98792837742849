<template>
  <div class="admin-container">
    <v-dialog v-model="event.show" persistent :width="500" mobile>
      <v-card>
        <v-card-title class="d-flex justify-space-between headline">
          <span>
            {{ event.type === "edit" ? "Edit Event" : "Add Event" }}
          </span>
          <v-icon color="#000" @click="closeModal()">fa fa-times</v-icon>
        </v-card-title>
        <v-card-text class="mt-5">
          <validation-observer
            ref="categoriesObserver"
            v-slot="{ handleSubmit }"
          >
            <v-form
              ref="categoriesForm"
              @submit.prevent="handleSubmit(addEditEvent)"
            >
              <validation-provider
                v-slot="{ errors }"
                name="Event Name"
                rules="required"
              >
                <v-text-field
                  v-model="event.eventName"
                  class="form-control mb-2"
                  :error-messages="errors"
                  required
                  outlined
                  dark
                  label="Event Name"
                  background-color="#f0f1f6"
                  color="#000"
                ></v-text-field>
              </validation-provider>
              <div class="d-flex justify-space-between">
                <validation-provider
                  v-slot="{ errors }"
                  name="Active Event"
                  rules="required"
                >
                  <v-checkbox
                    v-model="event.active"
                    :error-messages="errors"
                    :true-value="true"
                    :false-value="false"
                    label="Active Event"
                    class="mt-n2"
                  ></v-checkbox>
                </validation-provider>
                <v-checkbox
                  v-if="event.id && isSuperAdmin"
                  v-model="event.isDelete"
                  :true-value="true"
                  :false-value="false"
                  label="Delete Event"
                  class="mt-n2"
                ></v-checkbox>
              </div>
              <v-card-actions>
                <v-spacer />
                <v-btn
                  color="primary"
                  type="submit"
                  :loading="loading"
                  :disabled="loading"
                >
                  {{ event.type === "edit" ? "Save" : "Add" }}
                </v-btn>
                <v-btn color="#000" class="white--text" @click="closeModal()">
                  No
                </v-btn>
              </v-card-actions>
            </v-form>
          </validation-observer>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-container fluid>
      <v-row>
        <v-col cols="12" class="d-flex align-center justify-space-between">
          <span class="display-1 text-sm-h3 font-weight-bold">Events</span>
          <v-btn color="primary" @click="openModal({}, 'add')">
            Add Event <i class="fa fa-plus ml-1"></i>
          </v-btn>
        </v-col>
        <v-col cols="12" md="12">
          <v-card>
            <v-data-table
              :headers="eventsHeader"
              :items="events"
              class="elevation-1"
              :loading="dataLoading"
              must-sort
            >
              <template v-slot:[`header.name`]="{ header }">
                {{ header.text.toUpperCase() }}
              </template>
              <template v-slot:[`item.createdDate`]="{ item }">
                {{ item.createdDate.substring(0, 10) }}
              </template>
              <template v-slot:[`item.active`]="{ item }">
                {{ item.active ? "Yes" : "No" }}
              </template>
              <template v-slot:[`item.action`]="{ item }">
                <v-tooltip bottom>
                  <template #activator="{ on, attrs }">
                    <v-btn
                      icon
                      text
                      v-bind="attrs"
                      v-on="on"
                      @click="openModal(item, 'edit')"
                    >
                      <v-icon color="primary">fa fa-edit</v-icon></v-btn
                    >
                  </template>
                  Edit Event
                </v-tooltip>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import HomeService from "@/services/HomeService";
export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      eventsHeader: [
        { text: "Event Name", value: "eventName" },
        { text: "Added By", value: "addedByName" },
        { text: "Is Active", value: "active" },
        { text: "Created On", value: "createdDate" },
        { text: "Actions", value: "action", width: 100, sortable: false },
      ],
      event: {
        show: false,
        eventName: null,
        active: false,
        isDelete: false,
        id: 0,
        type: null,
      },
      loading: false,
    };
  },
  async created() {
    if (this.events.length === 0) {
      await this.$store.dispatch("receipt/FETCH_COMMON_DATA");
    }
  },
  computed: {
    ...mapState({
      dataLoading: (state) => state.receipt.loading,
      events: (state) => state.receipt.events,
      isSuperAdmin: (state) => state.auth.roleName === "Super Admin",
    }),
  },
  methods: {
    openModal(item, type) {
      this.event = {
        ...item,
        show: true,
        eventName: item.eventName,
        active: item.active,
        type: type,
        id: type === "add" ? 0 : item.id,
      };
    },
    addEditEvent() {
      this.loading = true;
      let event = JSON.parse(JSON.stringify(this.event));
      delete event.show;
      delete event.type;
      HomeService.addEditEvent(event)
        .then(async (res) => {
          this.loading = false;
          let alertMsg;
          if (this.event.type === "add") {
            alertMsg = "Event Created SuccessFully";
          } else if (this.event.isDelete) {
            alertMsg = "Event Deleted SuccessFully";
            this.$store.dispatch("receipt/FETCH_COMMON_DATA");
          } else {
            alertMsg = "Event Updated SuccessFully";
          }
          await this.$store.dispatch("notifications/setNotification", {
            type: "success",
            text: alertMsg,
          });
          if (this.event.type === "add") {
            this.$store.commit("receipt/addEvent", res.data.data);
          } else {
            this.$store.commit("receipt/editEvent", res.data.data);
          }
          this.closeModal();
        })
        .catch((error) => {
          this.loading = false;
          this.$store.dispatch("notifications/setNotification", {
            type: "error",
            text: error.response.data.errorMessage,
            status: error.response.status,
          });
        });
    },
    closeModal() {
      this.event = {
        show: false,
        eventName: null,
        active: false,
        isDelete: false,
        type: null,
        id: 0,
      };
      this.$refs.categoriesObserver.reset();
    },
  },
};
</script>
